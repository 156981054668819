<template>
  <div class="navigation-card bg-white rounded-lg shadow-sm mb-4">
    <div>
      <img class="w-100" :src="require(`@/assets/images/SparkMembers/Contents/views/Lives/sidebar-meeting.svg`)" />
    </div>
    <div class="p-4">
      <div class="d-flex align-items-center">
        <h4 class="font-heading text-dark font-weight-bold">
          {{ $t(`${prefixLang}.title`) }}
        </h4>
        <hs-icon
          icon="info-circle"
          class="informative-icon ml-3"
          :size="18"
          v-b-tooltip.hover.top.html="$t(`${prefixLang}.icon-tooltip`)"
        />
      </div>
      <h6 class="record-subtitle my-3">
        {{ $t(`${prefixLang}.subtitle`) }}
      </h6>
      <p class="record-description">
        {{ $t(`${prefixLang}.description`) }}
      </p>
      <div>
        <div class="d-flex align-items-center hs-bg-grey-16 px-3 py-2 time-counters">
          <hs-icon icon="clock" class="mr-1" :class="freeRecordTimeClass" :size="14" />
          <p class="m-0 grey-32 font-size-xs">
            {{ $t(`${prefixLang}.record-time-counters.free-time`) }}
            <span :class="freeRecordTimeClass">{{ recordTime }}</span>
          </p>
        </div>
        <div
          v-if="isRecordTimeExceeded"
          class="d-flex align-items-center hs-bg-white px-2 py-2 mt-2 time-counters exceed-timer"
        >
          <hs-icon icon="usd-circle" class="mr-1 text-info" :size="14" />
          <p class="m-0 grey-32 font-size-xs">
            {{ $t(`${prefixLang}.record-time-counters.exceeded-time`) }}
            <span class="text-info">{{ recordTimeExceeded }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import liveMeetService from '@/services/live_meet';
import ToastHelper from '@/shared/helpers/toast';

export default {
  data() {
    return {
      recordTime: '',
      recordTimeExceeded: '',
      recordingTimeLimit: 0,
      isRecordTimeExceeded: false,
      prefixLang: 'sparkmembers.contents.views.lives.record-live',
    };
  },
  created() {
    this.getRecordTime();
  },
  computed: {
    freeRecordTimeClass() {
      return this.isRecordTimeExceeded ? 'text-danger' : 'text-info';
    },
  },
  methods: {
    async getRecordTime() {
      try {
        const { recording_time, recording_time_limit } = await liveMeetService.getRecordTime();
        this.recordingTimeLimit = recording_time_limit;

        const { hours, minutes, seconds } = this.calculateTime(
          recording_time,
          this.checkExceedRecordLimit(recording_time)
        );

        this.recordTime = this.formatTimer({ hours, minutes, seconds });
      } catch (error) {
        ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.error`));
      }
    },

    calculateTime(recordingTime, exceeded) {
      const totalTime = exceeded ? 0 : Math.abs(this.recordingTimeLimit - recordingTime);
      const totalMinutes = Math.floor(totalTime / 60);
      const seconds = totalTime % 60;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return { hours, minutes, seconds };
    },

    calculateExceededTime(recordingTime) {
      this.recordTimeExceeded = this.formatTimer(this.calculateTime(recordingTime, false));
    },

    formatTimer(times) {
      const hours = times.hours.toLocaleString('pt-BR', { minimumIntegerDigits: 2 });
      const minutes = times.minutes.toLocaleString('pt-BR', { minimumIntegerDigits: 2 });
      const seconds = times.seconds.toLocaleString('pt-BR', { minimumIntegerDigits: 2 });

      return `${hours}:${minutes}:${seconds}`;
    },

    checkExceedRecordLimit(recordingTime) {
      const doesExceed = this.recordingTimeLimit - recordingTime < 0;
      if (doesExceed) {
        this.isRecordTimeExceeded = doesExceed;
        this.calculateExceededTime(recordingTime);
      }
      return doesExceed;
    },
  },
};
</script>

<style lang="scss" scoped>
.font-heading {
  font-size: 1.25rem;
}
.informative-icon {
  color: #3575d4;
}

.record-subtitle {
  font-size: 1rem;
}
.record-description {
  font-size: 0.874rem;
  color: #6f6f6f;
}
.time-counters {
  border-radius: 4px;
}

.exceed-timer {
  border: 2px solid #e3e3e3;
}
</style>
